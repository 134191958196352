<template>
  <div>
    视频管理
    <!-- 操作按钮取 -->
    <div class="btns-bar">
      <el-button type="primary" @click="openDialog"
        >添加视频</el-button
      >
    </div>
    <!-- 表格展示 -->
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="_id" label="视频ID" width="380"></el-table-column>
      <el-table-column
        prop="title"
        label="视频标题"
        width="380"
      ></el-table-column>
      <el-table-column prop="isLive" label="直播" width="100">
        <template slot-scope="scope">
          {{ scope.row.isLive ? "正在直播" : "" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            @click="editVideo(scope.row)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row._id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加对话框 -->
    <el-dialog
      v-loading="uploading"
      :title="isEdit ? '编辑视频' : '添加视频'"
      :visible.sync="showAddDialog"
      width="30%"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="视频标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="视频海报链接" prop="img">
          <el-upload
            class="image-upload"
            action=""
            :http-request="onUploadPoster"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :limit="1"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="imgPreviewDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          <el-input v-model="form.poster"></el-input>
        </el-form-item>
        <el-form-item label="视频描述">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入视频描述"
            v-model="form.desc"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="是否直播" prop="img">
          <el-switch
            v-model="form.isLive"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVideo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadMixin from '../../mixin/upload'

export default {
  data() {
    return {
      uploading: false,
      showAddDialog: false,
      tableData: [],
      form: {
        title: "",
        url: "",
        poster: "",
        isLive: false,
        desc: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入视频标题", trigger: "blur" },
          {
            min: 3,
            max: 200,
            message: "长度在 3 到 200 个字符",
            trigger: "blur",
          },
        ],
        url: [
          { required: true, message: "请输入视频源地址", trigger: "blur" },
          {
            min: 3,
            message: "长度在 3 个字符以上",
            trigger: "blur",
          },
        ],
      },
      isEdit: false,
    };
  },
  mixins: [uploadMixin],
  created() {
    this.getVideoList();
  },
  mounted() {
    // 绑定进度监听函数
    this.$ttUploader.on("complete", (data) => {
      if (data.type === "success") {
        this.form.poster =
          "https://cdn.jirou.fit/" +
          data.uploadResult.ImageUri +
          "~tplv-700tmnc25s-yasuo.image";
      }
      this.uploading = false
      this.$message({
        type: "success",
        message: data.extra.message,
      });
    });
    // 绑定错误监听函数
    this.$ttUploader.on("error", (infor) => {
      this.uploading = false;
      this.$message({
        type: "error",
        message: infor.extra,
      });
    });
  },
  watch: {
    showAddDialog(newVal) {
      if(!newVal) this.isEdit = false
    }
  },
  methods: {
    getVideoList() {
      this.$axios
        .get("/video/find_all")
        .then((res) => {
          this.tableData = res.data.res;
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        });
    },
    resetForm() {
      const reset = {
        title: "",
        url: "",
        poster: "",
        isLive: false,
        desc: "",
      };
      this.form = Object.assign({}, this.form, reset);
    },
    openDialog() {
      this.showAddDialog = true
      if (!this.isEdit) this.resetForm();
    },
    addVideo() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return this.$message.error("表单信息填写错误，请根据提示检查！");
        }
        const url = this.isEdit ? "/video/update" : "/video/add";
        this.$axios
          .post(url, JSON.stringify(this.form), {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("提交成功！");
            } else {
              this.$message.error("请求错误！");
            }
            this.showAddDialog = false;
            this.getVideoList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.message);
          });
      });
    },
    handleDelete(id) {
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestDelete(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    requestDelete(id) {
      this.$axios
        .post("/video/delete", JSON.stringify({ id: id }), {
          headers: {
            "x-auth-token": sessionStorage.getItem("bde_token"),
          },
        })
        .then((res) => {
          if (res.data.code === "success") {
            this.$message.success("删除成功");
            this.getVideoList();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          if (err.response.data.code === "token_timeout") {
            this.$message.error("登录过期，请重新登录！");
          }
        });
    },
    editVideo(row) {
      this.form = Object.assign({}, this.form, row);
      this.isEdit = true;
      this.showAddDialog = true;
    },
     onUploadPoster(param) {
      this.uploading = true;
      // 上传图片到火山引擎
      this.uploadImg(param)
    },
  },
};
</script>

<style scoped>
.btns-bar {
  margin: 20px 0;
}
.image-upload {
  margin-bottom: 10px;
}
</style>
