<template>
  <div>
    广告管理
    <!-- 操作按钮取 -->
    <div class="btns-bar">
      <el-button type="primary" @click="showAddDialog = true"
        >添加广告位</el-button
      >
    </div>
    <!-- 表格展示 -->
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="_id" label="广告ID" width="380"></el-table-column>
      <el-table-column
        prop="title"
        label="广告标题"
        width="380"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row._id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加对话框 -->
    <el-dialog v-loading="uploading" title="添加广告位" :visible.sync="showAddDialog" width="30%">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="图片链接" prop="img">
          <el-upload
            class="image-upload"
            action=""
            :http-request="onUploadPoster"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :limit="1"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="imgPreviewDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          <el-input v-model="form.img"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addAd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadMixin from "../../mixin/upload";

export default {
  data() {
    return {
      showAddDialog: false,
      tableData: [],
      form: {
        title: "",
        url: "",
        img: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入广告标题", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        img: [
          { required: true, message: "请输入广告图片URL", trigger: "blur" },
          {
            min: 3,
            max: 500,
            message: "长度在 3 到 500 个字符",
            trigger: "blur",
          },
        ],
      },
      uploading: false
    };
  },
  mixins: [uploadMixin],
  created() {
    this.getAdsList();
  },
  mounted() {
    // 绑定进度监听函数
    this.$ttUploader.on("complete", (data) => {
      if (data.type === "success") {
        this.form.img =
          "https://cdn.jirou.fit/" +
          data.uploadResult.ImageUri +
          "~tplv-700tmnc25s-yasuo.image";
      }
      this.uploading = false
      this.$message({
        type: "success",
        message: data.extra.message,
      });
    });
    // 绑定错误监听函数
    this.$ttUploader.on("error", (infor) => {
      this.uploading = false;
      this.$message({
        type: "error",
        message: infor.extra,
      });
    });
  },
  methods: {
    getAdsList() {
      this.$axios
        .get("/ads/find_all")
        .then((res) => {
          this.tableData = res.data.res;
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        });
    },
    addAd() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return this.$message.error("表单信息填写错误，请根据提示检查！");
        }
        this.$axios
          .post("/ads/add", JSON.stringify(this.form), {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("添加成功！");
            } else {
              this.$message.error("请求错误！");
            }
            this.showAddDialog = false;
            this.getAdsList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.message);
          });
      });
    },
    handleDelete(id) {
      this.$confirm("此操作将永久删除该广告位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestDelete(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    requestDelete(id) {
      this.$axios
        .post("/ads/delete", JSON.stringify({ id: id }), {
          headers: {
            "x-auth-token": sessionStorage.getItem("bde_token"),
          },
        })
        .then((res) => {
          if (res.data.code === "success") {
            this.$message.success("删除成功");
            this.getAdsList();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          if (err.response.data.code === "token_timeout") {
            this.$message.error("登录过期，请重新登录！");
          }
        });
    },
    onUploadPoster(param) {
      this.uploading = true;
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
  },
};
</script>

<style scoped>
.btns-bar {
  margin: 20px 0;
}
.image-upload {
  margin-bottom: 10px;
}
</style>
